<template>
  <div
    :class="[
      'vc-line-item',
      {
        'vc-line-item--removable': removable,
        'vc-line-item--disabled': disabled,
        'vc-line-item--selected': selected,
        'vc-line-item--deleted': deleted,
      },
    ]"
    @keydown="changeFocus"
  >
    <div v-if="$slots.before" class="vc-line-item__before">
      <slot name="before" />
    </div>

    <div class="vc-line-item__main">
      <!-- OPUS -->
      <VcTooltip
        v-if="selectable"
        placement="bottom-start"
        strategy="absolute"
        :show-by-click="false"
        class="hidden"
        :class="{ '!block': !isMobile && !isSelected }"
      >
        <template #trigger>
          <VcCheckbox
            v-model="isSelected"
            class="vc-line-item__checkbox"
            :disabled="disabled"
            @change="$emit('select', isSelected)"
          />
        </template>

        <template #content>
          <div class="shadow-sm-x-y w-[168px] rounded-sm bg-primary px-2 py-1.5 text-11 text-additional-50">
            {{ $t("common.messages.select_tooltip") }}
          </div>
        </template>
      </VcTooltip>
      <!-- !OPUS -->

      <VcCheckbox
        v-if="selectable"
        v-model="isSelected"
        class="vc-line-item__checkbox hidden"
        :class="{ '!flex': isSelected || isMobile }"
        :name="$t('common.labels.toggle_vendor_select')"
        :disabled="disabled"
        test-id="vc-line-item-checkbox"
        @change="$emit('select', isSelected)"
      />

      <!--  IMAGE -->
      <VcImage
        v-if="withImage"
        class="vc-line-item__img"
        :src="imageUrl"
        :fallback-src="fallbackSrc"
        :alt="name"
        size-suffix="sm"
        lazy
      />

      <!-- OPUS -->
      <VcBadge
        v-if="restricted"
        color="accent"
        size="lg"
        class="absolute left-[50px] top-[12px] z-[2] px-[5px] py-1 md:left-[48px] md:top-[16px] lg:top-[21px] xl:top-[16px]"
      >
        <VcIcon name="restricted" class="text-additional-50" size="xs" />
      </VcBadge>
      <div
        :class="[
          'vc-line-item__content',
          {
            'vc-line-item__content--with-image': withImage,
            'vc-line-item__content--selectable': !withImage && selectable,
          },
        ]"
      >
        <VcProductTitle
          class="vc-line-item__name"
          :disabled="disabled || deleted"
          :to="route"
          :title="name"
          :target="browserTarget"
        >
          <span v-html-safe="replaceUnicode(name)" />
          <!-- OPUS -->
          <p v-if="sku" class="vc-line-item__name--sku">{{ $t("common.labels.sku") }}: {{ sku }}</p>
          <!-- !OPUS -->
        </VcProductTitle>

        <div
          v-if="withProperties || withPrice"
          :class="[
            'vc-line-item__properties',
            {
              'vc-line-item__properties--wide': !withPrice,
              'vc-line-item__properties--hide': !withProperties && withPrice,
            },
          ]"
        >
          <template v-if="withProperties">
            <VcProperty
              v-for="property in properties"
              :key="property.name"
              :label="property.label!"
              :disabled="disabled || deleted"
            >
              {{ property.value }}
            </VcProperty>
          </template>

          <VcProperty v-if="sku" :label="$t('common.labels.sku')" :disabled="disabled">
            {{ sku }}
          </VcProperty>
          <VcProperty v-if="vendor" :label="$t('common.labels.vendor')" :disabled="disabled">
            {{ vendor.name }}
          </VcProperty>

          <VcProperty
            v-if="withPrice && !deleted"
            class="vc-line-item__property-price"
            :label="$t('ui_kit.labels.price_per_item')"
            :disabled="disabled"
          >
            <VcProductPrice :list-price="actualPrice || listPrice" :disabled="disabled" truncate />
          </VcProperty>
        </div>

        <VcProductPrice
          v-if="withPrice"
          class="vc-line-item__price"
          :list-price="listPrice"
          :actual-price="actualPrice"
          :disabled="disabled"
          align="end"
        />

        <div class="vc-line-item__mobile-row">
          <div class="vc-line-item__slot">
            <slot />
          </div>

          <!-- OPUS -->
          <template v-if="$slots.totals">
            <slot name="totals" />
          </template>
          <!-- !OPUS -->

          <VcProductPrice
            v-if="withTotal && !$slots.totals"
            class="vc-line-item__total"
            :list-price="total"
            align="end"
            :disabled="disabled"
            truncate
          />
        </div>

        <VcButton
          v-if="removable"
          :aria-label="$t('ui_kit.buttons.remove_from_cart')"
          class="vc-line-item__remove-button"
          color="neutral"
          size="sm"
          variant="no-background"
          icon="delete-thin"
          :disabled="disabled"
          @click="$emit('remove')"
        />
      </div>
    </div>

    <div v-if="$slots.after" class="vc-line-item__after">
      <slot name="after" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { ref, watchEffect } from "vue";
import { replaceUnicode } from "@/shared/opus";
import type { Property, MoneyType, CommonVendor } from "@/core/api/graphql/types";
import type { RouteLocationRaw } from "vue-router";

interface IEmits {
  (event: "remove"): void;
  (event: "select", value: boolean): void;
}

interface IProps {
  imageUrl?: string;
  name: string;
  route?: RouteLocationRaw;
  properties?: Property[];
  listPrice?: MoneyType;
  actualPrice?: MoneyType;
  total?: MoneyType;
  selectable?: boolean;
  selected?: boolean;
  removable?: boolean;
  disabled?: boolean;
  deleted?: boolean;
  withImage?: boolean;
  withProperties?: boolean;
  withPrice?: boolean;
  withTotal?: boolean;
  vendor?: CommonVendor;
  browserTarget?: BrowserTargetType;
  // OPUS
  restricted?: boolean;
  fallbackSrc?: string;
  sku?: string;
  // !OPUS
}

defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  properties: () => [],
  browserTarget: "_blank",
});

const isSelected = ref<boolean>(true);

function changeFocus(event: KeyboardEvent) {
  const { target: targetElement, currentTarget } = event;
  if (!(currentTarget instanceof HTMLElement) || !(targetElement instanceof HTMLElement)) {
    return;
  }
  const targetClassList = targetElement.classList;
  let nextElement: Element | null = null;
  switch (event.key) {
    case "ArrowUp":
      nextElement = currentTarget.previousElementSibling;
      break;
    case "ArrowDown":
      nextElement = currentTarget.nextElementSibling;
      break;
  }
  if (nextElement && nextElement.classList.contains("vc-line-item") && nextElement instanceof HTMLElement) {
    const nextTargetElement = nextElement.querySelector(`.${[...targetClassList].join(".")}`);
    (nextTargetElement as HTMLElement | null)?.focus();
  }
}

watchEffect(() => {
  isSelected.value = props.selected;
});

// OPUS
const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.smaller("lg");
// !OPUS
</script>

<style lang="scss">
.vc-line-item {
  $selected: "";
  $removable: "";
  $removed: "";
  $deleted: "";
  $disabled: "";

  --bg-color: var(--color-additional-50);

  @apply relative flex flex-col gap-2 p-3 rounded border shadow-md bg-[--bg-color];

  @container (width > theme("containers.2xl")) {
    @apply p-4 rounded-none border-0 shadow-none;
  }

  &--selected {
    $selected: &;
    --bg-color: var(--color-info-50);
  }

  &--removable {
    $removable: &;
  }

  &--deleted {
    $deleted: &;
  }

  &--removed {
    $removed: &;
  }

  &--disabled {
    $disabled: &;
  }

  &__before,
  &__after {
    &:empty {
      @apply hidden;
    }
  }

  &:first-child {
    @container (width > theme("containers.2xl")) {
      @apply rounded-t-[inherit];
    }
  }

  &:last-child {
    @container (width > theme("containers.2xl")) {
      @apply rounded-b-[inherit];
    }
  }

  &__main {
    @apply flex items-start min-h-[1.25rem];

    @container (width > theme("containers.2xl")) {
      @apply items-center gap-3;
    }
  }

  &__checkbox {
    @apply flex-none absolute top-0.5 left-0.5 p-2 rounded bg-[--bg-color];

    @container (width > theme("containers.2xl")) {
      @apply static top-auto left-auto -m-2;
    }
  }

  &__img {
    @apply shrink-0 size-16 rounded border object-contain object-center bg-additional-50;

    @container (width > theme("containers.2xl")) {
      @apply size-12;
    }

    @container (width > theme("containers.4xl")) {
      @apply size-16;
    }

    #{$disabled} &,
    #{$deleted} & {
      @apply opacity-50;
    }
  }

  &__content {
    @apply w-full;

    @container (width > theme("containers.2xl")) {
      @apply contents;
    }

    &--with-image {
      @apply w-[calc(100%-theme(width.16))] ps-3;
    }

    &--selectable {
      @apply ps-8;
    }
  }

  &__name {
    @apply text-sm;

    @container (width > theme("containers.2xl")) {
      @apply grow min-h-0;
    }

    #{$removable} & {
      @apply pr-10;

      @container (width > theme("containers.2xl")) {
        @apply pr-0;
      }
    }

    // OPUS
    &--sku {
      @apply hidden text-xs text-additional-50;
    }
    // !OPUS
  }

  &__properties {
    @apply flex-none mt-3;

    @container (width > theme("containers.2xl")) {
      @apply mt-0 w-40;
    }

    @container (width > theme("containers.4xl")) {
      @apply w-[11.875rem];
    }

    &--wide {
      @container (width > theme("containers.4xl")) {
        @apply w-[15.5rem];
      }
    }

    &--hide {
      @container (width > theme("containers.4xl")) {
        @apply hidden;
      }
    }
  }

  &__property-price {
    @container (width > theme("containers.4xl")) {
      @apply hidden;
    }

    * {
      @apply leading-[inherit] #{!important};
    }
  }

  &__price {
    --vc-product-price-font-size: theme(fontSize.sm);

    @container (width <= theme("containers.4xl")) {
      @apply hidden #{!important};
    }

    @container (width > theme("containers.4xl")) {
      @apply shrink-0 w-[8.25rem];
    }

    #{$deleted} & {
      @container (width > theme("containers.4xl")) {
        @apply invisible;
      }
    }
  }

  &__mobile-row {
    @apply contents;

    @container (width <= theme("containers.2xl")) {
      @apply flex items-center gap-3 mt-3;
    }
  }

  &__slot {
    @apply flex-none empty:hidden;

    &:has(.vc-add-to-cart--hide-button, * .vc-add-to-cart--hide-button) {
      @apply w-[6.5rem];
    }

    &:has(
        .add-to-cart,
        * .add-to-cart,
        .vc-add-to-cart:not(.vc-add-to-cart--hide-button),
        * .vc-add-to-cart:not(.vc-add-to-cart--hide-button)
      ) {
      @apply w-full;

      @container (width > theme("containers.md")) {
        @apply w-72;
      }

      @container (width > theme("containers.2xl")) {
        @apply w-48;
      }

      @container (width > theme("containers.3xl")) {
        @apply w-[15.7rem];
      }
    }

    #{$deleted} & {
      @container (width <= theme("containers.2xl")) {
        @apply hidden;
      }

      @container (width > theme("containers.2xl")) {
        @apply invisible;
      }
    }
  }

  &__total {
    --vc-product-price-font-size: theme(fontSize.sm);

    @apply w-full min-w-0;

    @container (width > theme("containers.2xl")) {
      --vc-product-price-font-size: theme(fontSize.sm);

      @apply shrink-0 w-[6.5rem];
    }

    @container (width > theme("containers.4xl")) {
      --vc-product-price-font-size: theme(fontSize.base);

      @apply w-[8.625rem];
    }
  }

  &__remove-button {
    @apply shrink-0;

    @container (width <= theme("containers.2xl")) {
      @apply top-0.5 right-0.5 absolute #{!important};
    }

    @container (width > theme("containers.2xl")) {
      @apply -my-2 -me-2;
    }
  }
}
</style>
